import styled from 'styled-components'

import Icon from 'gatsby-theme-atomic-design/src/atoms/Icon'

const Logo = styled(Icon).attrs({
  icon: 'FooterLogo',
})`
  margin: 7px 0;
  height: 50px;
  width: 189px;
`

export default Logo
